import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
  Typography,
  Col,
  Row,
} from 'antd'

import { I18n } from 'react-redux-i18n'

const {Title} = Typography

export default function Success () {
  const location = useLocation()
  const { state } = location
  const {refundRecordId,shopNo} = state
  console.dir(refundRecordId)
  return (
    <>
      <Row style={{padding: '15px'}}>
        <Col span={24}>
          <Typography>
            <Title level={4}>{I18n.t('contact_us',
              {email: 'info@oveser.com'})}</Title>
          </Typography>
        </Col>
      </Row>
      <Row justify="end" style={{paddingRight: '25px'}}>
        <Col>
          <Link to={`/${shopNo}/${refundRecordId}`}>{I18n.t(
            'return_to_modify')}</Link>
        </Col>
      </Row>
    </>
  )
}
