export default {
  "notice": "请如实填写以下资料，方便我们核对，其中带*的为必填项",
  "contact_number": "联系电话",
  "bank_transfer": "银行转账",
  "e_wallet": "经原本的电子钱包",
  "coupon": "等值优惠劵二维码",
  "payment": "支付方式",
  "payment_placeholder": "请选择你付款时所使用的支付方式",
  "reason_for_refund": "退款原因",
  "switch_language": "切换语言",
  "without_delivery": "支付成功但没出货",
  "delivery_with_problem": "已取到货品但有问题",
  "other_reason": "其它原因",
  "octopus": "八达通",
  "ali_weChat": "支付宝 / 微信支付",
  "crypto": "加密货币",
  "payment_records": "支付记录",
  "screen_capture": "截图 / 相片（1-3张图片）",
  "amount_pay_time": "金额，支付时间",
  "click_to_upload": "点击上传",
  "payment_amount": "支付金额",
  "payment_time": "支付时间",
  "refund_method": "退款方式",
  "payee_name": "收款人姓名 (大写英文)",
  "bank_account_number": "银行账户号码",
  "submit": "提交",
  "site_number": "场地编号",
  "contact_us": "你已成功提交退款申请，请等待我们审核。如有其它查询，可电邮至：%{email}",
  "invalid_file_type": "文件格式错误",
  "errorMessageUnknownError": "未知错误",
  "phone_whatsApp_required_tip": "请填写联系电话或WhatsApp其中之一",
  "picture": "图片",
  "return_to_modify": "返回修改",
  "at_least_one_picture": "请至少上传一张图片",
  "site_address": "场地地址",
  "refund_approved": "此退款申请已由管理员审批通过，暂不可修改",
  "site_name": "场地名称",
  "product_name": "产品名称",
  "bank_name": "银行名称",
  "bank_transfer_tip": "我了解银行转帐可能需要 30 天的时间来处理",
  "errorMessageRequiredSelect": "此项是必选项，请勾选",
  "enter_uppercase_english": "请输入大写英文",
  "INVALID_PARAMS": "无效的参数",
  "bank_transfer_message": "(30个工作天内处理)",
  "e_wallet_transfer_message": "(7个工作天内处理)",
}