export default {
  "notice": "Please fill in the following information truthfully for our convenience in verification. Items marked with * are mandatory",
  "contact_number": "Contact Number",
  "bank_transfer": "Bank Transfer",
  "e_wallet": "Through The Original Electronic Wallet",
  "coupon": "Equivalent Coupon QR Code",
  "payment": "Payment",
  "payment_placeholder": "Please choose the payment method you used when making the payment",
  "reason_for_refund": "Reason For Refund",
  "switch_language": "Switch language",
  "without_delivery": "Payment Successful But No Shipment",
  "delivery_with_problem": "The goods have been picked up but there are issues",
  "other_reason": "Other Reason",
  "octopus": "Octopus",
  "ali_weChat": "Alipay / WeChat Pay",
  "crypto": "Crypto",
  "payment_records": "Payment Record",
  "screen_capture": "Screenshots/Photos (1-3 Images)",
  "amount_pay_time": "Payment Amount And Payment time",
  "click_to_upload": "Click To Upload",
  "payment_amount": "Payment Amount",
  "payment_time": "Paid At",
  "refund_method": "Refund Method",
  "payee_name": "Payee Name (Uppercase)",
  "bank_account_number": "Bank Account Number",
  "submit": "Submit",
  "site_number": "Site No.",
  "contact_us": "You have successfully submitted a refund request, please wait for our review.If you have any other inquiries, you can email us at：%{email}",
  "invalid_file_type": "Invalid File Type",
  "errorMessageUnknownError": "Unknown Error",
  "phone_whatsApp_required_tip": "Please provide your contact phone number or one of WhatsApp's options",
  "picture": "Picture",
  "return_to_modify": "Return To Modify",
  "at_least_one_picture": "Please upload at least one image",
  "site_address": "Site Address",
  "refund_approved": "This refund request has been approved by the administrator and cannot be modified temporarily",
  "site_name": "Site Name",
  "product_name": "Product Name",
  "bank_name": "Bank Name",
  "bank_transfer_tip": "I understand bank transfers may take up to 30 days to process",
  "errorMessageRequiredSelect": "This is a required option, please check it",
  "enter_uppercase_english": "Please enter uppercase English",
  "INVALID_PARAMS": "Invalid Params",
  "bank_transfer_message": "(Processed in 30 working days)",
  "e_wallet_transfer_message": "(Processed in 7 working days)",
}